import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <h1>Strona nie została znaleziona</h1>
    <p>Niestety ta droga nie prowadzi do zadnego celu.</p>
  </Layout>
);

export default NotFoundPage;
